import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {push} from 'connected-react-router';
import {userActions} from 'features/User';
import i18next from 'i18next';
import {UserRoles} from 'interfaces';
import {Epic} from 'redux-observable';
import {
  catchError,
  concat,
  concatMap,
  filter,
  from,
  ignoreElements,
  mergeMap,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {OnboardingService} from 'services/api';
import {MinorConsentService} from 'services/api/MinorConsent';
import {calculateAge} from 'utils';

import {minorConsentActions} from './minorConsentSlice';

export const updateMinorConsentSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.updateMinorConsentSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t('minorConsentStatus.successful'),
      });
    }),
    ignoreElements(),
  );

export const updateMinorConsentFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.updateMinorConsentFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: i18next.t('minorConsentStatus.failed'),
      });
    }),
    ignoreElements(),
  );

export const updateMinorConsentEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.updateMinorConsent.match),
    switchMap(({payload}) =>
      from(OnboardingService.updateMinorConsent(payload)).pipe(
        mergeMap(({data: {message: user}}) => {
          const actions: AppActions[] = [
            userActions.checkSession(user.role),
            userActions.updateMinorConsentSuccess({message: user}),
          ];
          if (user.dateOfBirth && calculateAge(user.dateOfBirth) >= 18) {
            actions.push(
              push({
                pathname: '/onboarding-therapy-intake',
                search: `?from=home`,
              }),
            );
          }
          return actions;
        }),
        catchError((message: string) =>
          concat(
            of(userActions.updateMinorConsentFailure()),
            of(userActions.setAsyncError({filter: 'user', message})),
          ),
        ),
      ),
    ),
  );

export const updateMinorConsentStepSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.updateMinorConsentStepSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: i18next.t('minorConsentStatus.successful'),
      });
    }),
    ignoreElements(),
  );

export const updateMinorConsentStepFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.updateMinorConsentStepFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: i18next.t('minorConsentStatus.failed'),
      });
    }),
    ignoreElements(),
  );

export const updateMinorConsentStepEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(userActions.updateMinorConsentStep.match),
    switchMap(({payload}) =>
      from(OnboardingService.updateMinorConsentStep(payload)).pipe(
        mergeMap(({data: {message: user}}) => {
          const actions: AppActions[] = [
            userActions.checkSession(user.role),
            userActions.updateMinorConsentStepSuccess({message: user}),
          ];
          if (payload.skip) {
            actions.push(
              push({
                pathname: '/onboarding-therapy-intake',
                search: `?from=home`,
              }),
            );
          }
          return actions;
        }),
        catchError((message: string) =>
          concat(
            of(userActions.updateMinorConsentStepFailure()),
            of(userActions.setAsyncError({filter: 'user', message})),
          ),
        ),
      ),
    ),
  );

export const getMinorConsentDataRequestEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(minorConsentActions.getMinorConsentDataRequest.match),
    concatMap(({payload}) =>
      from(MinorConsentService.getMinorConsentData(payload)).pipe(
        concatMap(({data: {message: minorConsentResponse}}) => [
          minorConsentActions.getMinorConsentDataRequestSuccess(
            minorConsentResponse,
          ),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'minorConsent',
                message,
              }),
            ),
            of(minorConsentActions.getMinorConsentDataRequestFailure(message)),
          ),
        ),
      ),
    ),
  );

const getMinorConsentDataFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(minorConsentActions.getMinorConsentDataRequestFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const setMinorConsentDataRequestEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(minorConsentActions.setMinorConsentDataRequest.match),
    concatMap(({payload: {location, ...restPayload}}) =>
      from(MinorConsentService.setMinorConsentData(restPayload)).pipe(
        concatMap(({data: {message: minorConsentResponse}}) => [
          userActions.checkSession(UserRoles.member),
          minorConsentActions.setMinorConsentDataRequestSuccess(
            minorConsentResponse,
          ),
          userActions.setNotification({
            messageType: 'success',
            message: i18next.t('parentalConsentSuccess'),
          }),
          ...(!location
            ? [
                push({
                  pathname: '/onboarding-therapy-intake',
                  search: `?from=home`,
                }),
              ]
            : []),
        ]),
        catchError((message: string) =>
          concat(
            of(
              userActions.setAsyncError({
                filter: 'minorConsent',
                message,
              }),
            ),
            of(minorConsentActions.setMinorConsentDataRequestFailure(message)),
          ),
        ),
      ),
    ),
  );

const setMinorConsentDataFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(minorConsentActions.setMinorConsentDataRequestFailure.match),
    tap(({payload}) => {
      Toast({type: 'error', message: payload});
    }),
    ignoreElements(),
  );

export const minorConsentEpics = [
  getMinorConsentDataRequestEpic,
  getMinorConsentDataFailureEpic,
  setMinorConsentDataRequestEpic,
  setMinorConsentDataFailureEpic,
  updateMinorConsentEpic,
  updateMinorConsentFailureEpic,
  updateMinorConsentSuccessEpic,
  updateMinorConsentStepEpic,
  updateMinorConsentStepFailureEpic,
  updateMinorConsentStepSuccessEpic,
];
