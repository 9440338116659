import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {RootState} from 'app/rootReducer';
import {LoadingSpinner} from 'components';
import Toast from 'components/Basic/Toast';
import {withTherapyIntake} from 'components/HOCs/withTherapyIntake';
import {selectAppointment} from 'features/Appointment';
import {providerActions} from 'features/Provider';
import {selectMemberById} from 'features/Provider/Members/membersSelectors';
import {selectUserProfile} from 'features/User';
import {useQuery, useWhereby} from 'hooks';
import {IntercomEventTypes, UserRoles, WherebyRoomModeTypes} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {trackIntercomEvent} from 'services/api/Intercom/helpers';
import {isMember, isProvider} from 'utils';

import WherebyMemberVideoPage from '../Member/WherebyMemberVideoPage';
import WherebyProviderVideoPage from '../Provider/WherebyProviderVideoPage';
import {videoActions} from '../videoSlice';

import WherebyMemberLeftCallView from './WherebyMemberLeftCallView';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'whereby-embed': {
        room: string;
        displayName: string;
        screenshare: boolean;
        floatSelf: boolean;
        class: string;
        ref:
          | React.MutableRefObject<null>
          | Dispatch<SetStateAction<HTMLElement | null>>;
      };
    }
  }
}

const WherebyVideoPage = () => {
  const [wherebyElement, setWherebyElement] = useState<HTMLElement | null>(
    null,
  );
  const {t} = useTranslation();

  const urlParams = useQuery();
  const providerRole = urlParams.get('provider') as UserRoles;
  const providerId = urlParams.get('providerId');
  const duration = urlParams.get('duration') ?? '';
  const appointmentTime = decodeURI(useQuery().get('appointmentTime') ?? '');
  const appointmentID = urlParams.get('appointmentID') ?? '';
  const roomMode = (urlParams.get('roomMode') ?? '') as WherebyRoomModeTypes;
  const isGroupCall = roomMode === 'group';
  const [hasLeftCall, setHasLeftCall] = useState<boolean>(false);

  const patientId = urlParams.get('patientId') ?? '';

  const user = useSelector(selectUserProfile);
  const member = useSelector((state: RootState) => {
    return selectMemberById(state, patientId || '');
  });
  const appointmentDetails = useSelector(
    selectAppointment(roomMode, Number(appointmentID)),
  );

  const getParticipantId = () => {
    if (isMember(user)) {
      return providerId;
    } else if (isGroupCall) {
      const participants = appointmentDetails?.participants ?? [];
      return participants.map(p => p?.patientId).join(',') || '';
    } else {
      return patientId;
    }
  };

  const {videoRoomUrl} = useWhereby(
    isProvider(user) ? UserRoles.member : providerRole,
    getParticipantId(),
    appointmentTime,
    appointmentID,
    roomMode,
  );

  const dispatch = useDispatch();

  function logEvent(event: any) {
    if (event.type === 'leave') {
      if (user?.role === UserRoles.member) {
        dispatch(videoActions.rateVideoCall({appointmentID}));
        setHasLeftCall(true);
      } else {
        dispatch(
          videoActions.rateVideoCall({
            appointmentID,
          }),
        );
      }
    }
    if (event.type === 'participant_leave' && user?.role === UserRoles.member) {
      dispatch(videoActions.rateVideoCall({appointmentID}));
      setHasLeftCall(true);
      Toast({type: 'info', message: t('providerEndedMeeting')});
    }

    if (
      ['leave', 'participant_leave'].includes(event.type) &&
      user?.role === UserRoles.member
    ) {
      trackIntercomEvent(IntercomEventTypes.PATIENT_SESSION_ENDED);
    }
  }

  useEffect(() => {
    wherebyElement?.addEventListener('leave', logEvent);
    wherebyElement?.addEventListener('participant_leave', logEvent);

    return () => {
      wherebyElement?.removeEventListener('leave', logEvent);
      wherebyElement?.removeEventListener('participant_leave', logEvent);
    };
  }, [wherebyElement]);

  useEffect(() => {
    if (isProvider(user)) {
      if (patientId && !isGroupCall) {
        dispatch(
          providerActions.getMemberById({
            patientId,
            role: user?.role ?? UserRoles.therapist,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  const formatname = (name?: string) => {
    if (name?.toLowerCase() === 'amy halanski') return 'Dr. Halanski MD';
    return `Dr. ${name}`;
  };

  const roomURL = () => {
    if (isMember(user)) {
      return `${videoRoomUrl}/?people=off&displayName=${
        user?.fullName
      }&roomKey=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtZWV0aW5nSWQiOiI4NDU5ODE5MyIsInJvb21SZWZlcmVuY2UiOnsicm9vbU5hbWUiOiIvNWViMDljNTI0NDE4ZTEwLTczMzQ1ZjM0N2U4NWQ5MDAyYzc0YTdjLTNjMzktNDM1My04NjdlLTUzY2E0ZDY4MDIyMSIsIm9yZ2FuaXphdGlvbklkIjoiMTM4MzM1In0sImlzcyI6Imh0dHBzOi8vYWNjb3VudHMuc3J2LndoZXJlYnkuY29tIiwiaWF0IjoxNzEyMTk1MjIwLCJyb29tS2V5VHlwZSI6Im1lZXRpbmdIb3N0In0.Bqo271w59JKC1pmjwd_u_j2T4H5lAwICVqkqtgbHSKU&background=on&locking=off&logo=off&settingsButton=off&moreButton=off&participantCount=off&timer=on&metadata=${JSON.stringify(
        {
          patientId: user.patientId,
          providerType: providerRole,
          providerId,
          appointmentID,
        },
      )}`;
    } else {
      return `${videoRoomUrl}&people=off&displayName=${
        providerRole === UserRoles.prescriber
          ? formatname(user?.fullName)
          : user?.fullName
      }&people=off&background=off&logo=off&locking=off&settingsButton=off&moreButton=off&timer=on&metadata=${JSON.stringify(
        {
          providerType: providerRole,
          providerId: user?._id,
          appointmentID,
        },
      )}`;
    }
  };
  const displayName = () => {
    let n;
    if (isMember(user)) {
      n =
        providerRole === UserRoles.prescriber
          ? user?.prescriberDetails?.fullName
          : user?.therapistDetails?.fullName;
    } else {
      n = isGroupCall
        ? appointmentDetails?.participants
            ?.map(p => `${p.firstName} ${p.lastName}`)
            .join(', ')
        : member?.fullName;
    }

    return `Call with ${n}`;
  };

  if (isMember(user) && providerRole && appointmentDetails) {
    return (
      <WherebyMemberVideoPage user={user} appointment={appointmentDetails}>
        {videoRoomUrl && process.env.NODE_ENV === 'production' ? (
          <>
            {hasLeftCall ? (
              <WherebyMemberLeftCallView
                user={user}
                duration={duration}
                providerRole={providerRole}
                appointmentId={appointmentID}
              />
            ) : (
              <whereby-embed
                ref={setWherebyElement}
                room={roomURL()}
                displayName={displayName()}
                screenshare
                floatSelf
                class="w-full h-full"
              />
            )}
          </>
        ) : (
          <section className="flex flex-col items-center justify-center w-full h-full">
            <div className="pt-24">
              <LoadingSpinner color="#747475" height={80} />
            </div>
            {process.env.NODE_ENV !== 'production' ? (
              <p className="p-8 text-center text-red-500">
                {t('videoOnlyInProduction')}
              </p>
            ) : null}
            <p className="pt-4">{t('pleaseWait')}</p>
          </section>
        )}
      </WherebyMemberVideoPage>
    );
  } else if (
    (isProvider(user) && member) ||
    (isProvider(user) && isGroupCall)
  ) {
    return (
      <WherebyProviderVideoPage
        patientId={member?.patientId ?? ''} // will be undefined in group call
        patientName={member?.fullName ?? ''} // will be undefined in group call
      >
        <section className="absolute z-10 top-0 w-full text-center pt-5">
          <p>{t('sessionDuration', {duration})}</p>
        </section>
        {videoRoomUrl && process.env.NODE_ENV === 'production' ? (
          <div className="absolute top-0 bottom-0 left-0 right-0 z-20">
            <whereby-embed
              ref={setWherebyElement}
              room={roomURL()}
              displayName={displayName()}
              screenshare
              floatSelf
              class="w-full h-full"
            />
          </div>
        ) : (
          <section className="flex flex-col items-center justify-center w-full h-full ">
            <div className="pt-24">
              <LoadingSpinner color="#747475" height={80} />
            </div>
            {process.env.NODE_ENV !== 'production' ? (
              <p className="p-8 text-red-500 text-center">
                {t('videoOnlyInProduction')}
              </p>
            ) : null}
            <p className="pt-4">{t('pleaseWait')}</p>
          </section>
        )}
      </WherebyProviderVideoPage>
    );
  } else {
    return (
      <section className="flex flex-col items-center justify-center w-full h-full">
        <div className="pt-24">
          <LoadingSpinner color="#747475" height={80} />
        </div>
        <p className=" pt-4 md:pt-8">{t('loadingMember...')}</p>
      </section>
    );
  }
};

export default withTherapyIntake(WherebyVideoPage);
