import {AppActions, RootState} from 'app/rootReducer';
import Toast from 'components/Basic/Toast';
import {userActions} from 'features/User';
import {Epic} from 'redux-observable';
import {concat, from, of} from 'rxjs';
import {
  catchError,
  filter,
  ignoreElements,
  mergeMap,
  tap,
} from 'rxjs/operators';
import {ProviderService} from 'services/api/Provider';

import {CalendarEpics} from './Calendar/calendarEpics';
import {WidgetsEpics} from './Dashboard/Widgets/widgetsEpics';
import {wellniteContentEpics} from './Marketing/WellniteContent/wellniteContentEpics';
import {MemberEpics} from './Member/memberEpics';
import {MembersEpics} from './Members/membersEpics';
import {notesEpics} from './Notes/notesEpics';
import {PaymentEpics} from './Payments/paymentEpics';
import {AccountEpics} from './Profile/profileEpics';
import {ReferralEpics} from './Referral/referralEpics';
import {RegisterEpics} from './Register/registerEpics';
import {providerActions} from './providerSlice';

const getMedicationsEpic: Epic<AppActions, AppActions, RootState> = action$ =>
  action$.pipe(
    filter(providerActions.getMedications.match),
    mergeMap(() =>
      from(ProviderService.getMedications()).pipe(
        mergeMap(({data}) => [
          providerActions.getMedicationsSuccess(data.message.medications),
        ]),
        catchError((message: string) =>
          concat(
            of(userActions.setAsyncError({filter: 'provider', message})),
            of(providerActions.getNotesFailure()),
          ),
        ),
      ),
    ),
  );

const getMedicationsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.getMedicationsFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

const updateMedicationsEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateMedications.match),
    mergeMap(({payload}) =>
      from(ProviderService.updateMedications(payload)).pipe(
        mergeMap(() => [
          providerActions.updateMedicationsSuccess(),
          providerActions.getMemberById(payload),
        ]),
        catchError((message: string) =>
          concat(of(providerActions.updateMedicationsFailure(message))),
        ),
      ),
    ),
  );

const updateMedicationsFailureEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateMedicationsFailure.match),
    tap(({payload: message}) => {
      if (message) {
        Toast({type: 'error', message});
      }
    }),
    ignoreElements(),
  );

const updateMedicationsSuccessEpic: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateMedicationsSuccess.match),
    tap(() => {
      Toast({
        type: 'success',
        message: 'Current Medication updated successfully',
      });
    }),
    ignoreElements(),
  );

const updateProfessionalHeadshot: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProfessionalHeadshot.match),
    mergeMap(({payload}) =>
      from(ProviderService.updateProfessionalHeadshot(payload)).pipe(
        mergeMap(() => [providerActions.updateProfessionalHeadshotSuccess()]),
        catchError((message: string) =>
          concat(
            of(providerActions.updateProfessionalHeadshotFailure(message)),
          ),
        ),
      ),
    ),
  );

const updateProfessionalHeadshotFailure: Epic<
  AppActions,
  AppActions,
  RootState
> = action$ =>
  action$.pipe(
    filter(providerActions.updateProfessionalHeadshotFailure.match),
    tap(() => {
      Toast({
        type: 'error',
        message: 'Failed to upload professional headshot',
        position: 'bottom-left',
      });
    }),
    ignoreElements(),
  );

export const providerEpics = [
  notesEpics,
  wellniteContentEpics,
  MembersEpics,
  MemberEpics,
  AccountEpics,
  PaymentEpics,
  CalendarEpics,
  RegisterEpics,
  ReferralEpics,
  WidgetsEpics,
  [
    getMedicationsEpic,
    getMedicationsFailureEpic,
    updateMedicationsEpic,
    updateMedicationsFailureEpic,
    updateMedicationsSuccessEpic,
    updateProfessionalHeadshot,
    updateProfessionalHeadshotFailure,
  ],
].flatMap(epic => epic);
