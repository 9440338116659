import React, {Fragment, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {PlusIcon} from 'components/Basic';
import {selectUserProfile} from 'features/User';
import {useClickOutside, usePlanDetails} from 'hooks';
import {AppointmentTypes, MemberProfile, StripePlanTypes} from 'interfaces';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {
  isDigitalPracticeOutOfPocketMember,
  isInNetworkDigitalPracticeMember,
  isInsuranceZocdocMember,
  isUserFromUnitedStates,
} from 'utils';

import {Menu, Transition} from '@headlessui/react';

interface IProps {
  fontSize?: string;
  fontWeight?: string;
  btnWidth?: string;
  btnHeight?: string;
  btnClasses?: string;
  optClasses?: string;
  border?: string;
  borderColor?: string;
  menuPosition?: string;
  onSetSessionType?: (sessionType: AppointmentTypes) => void;
  reverse?: boolean;
  isMobile?: boolean;
}

export default function MemberBookingOptions({
  fontSize = 'base',
  fontWeight = 'semibold',
  btnWidth = 'w-[110px]',
  btnHeight = 'h-12',
  btnClasses = 'mr-0 sm:mr-6 leading-tight',
  optClasses = 'text-black',
  border = 'border',
  borderColor = 'blue-600',
  menuPosition = 'top-14 right-0 lg:right-6 origin-top-right',
  onSetSessionType,
  reverse = false,
  isMobile = false,
}: IProps) {
  const history = useHistory();
  const user = useSelector(selectUserProfile) as MemberProfile;
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {t} = useTranslation();

  const {userCurrentPlan} = usePlanDetails();

  const isTherapyPlan = [
    'curandero',
    'together_weekly',
    'therapy',
    'new_therapy',
  ].includes(userCurrentPlan);

  const isMedicalPlan = [
    'curandero',
    'together_weekly',
    'medicalcare',
    'mindfulness',
  ].includes(userCurrentPlan);

  const isMedicalCarePlusTherapy =
    userCurrentPlan === StripePlanTypes.medicalcare_plus_therapy ||
    (isDigitalPracticeOutOfPocketMember(user) && isUserFromUnitedStates(user));

  useClickOutside(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    const element = wrapperRef.current;
    if (!element) {
      return;
    }
  }, [wrapperRef.current]);

  const bookingOptions = [];
  if (isTherapyPlan) {
    bookingOptions.push({
      label: t('therapy_session'),
      appointmentType: AppointmentTypes.video_call_with_therapist,
    });
  } else if (isMedicalPlan) {
    bookingOptions.push({
      label: t('doctor_consultation'),
      appointmentType: AppointmentTypes.doctor_consultation,
    });
  } else if (
    isMedicalCarePlusTherapy ||
    isInsuranceZocdocMember(user) ||
    isInNetworkDigitalPracticeMember(user)
  ) {
    bookingOptions.push({
      label: t('doctor_consultation'),
      appointmentType: AppointmentTypes.doctor_consultation,
    });
    bookingOptions.push({
      label: t('therapy_session'),
      appointmentType: AppointmentTypes.video_call_with_therapist,
    });
  }

  const handleBookingOptionClick = (appointmentType: AppointmentTypes) => {
    if (
      appointmentType === AppointmentTypes.video_call_with_therapist &&
      !user.hasCompletedTherapyIntake
    ) {
      history.push('/onboarding-lobby/therapy');
    } else if (
      appointmentType === AppointmentTypes.doctor_consultation &&
      Number(user?.regStage) <= 19 &&
      user?.governmentID === undefined
    ) {
      history.push('/onboarding-lobby/medical');
    } else {
      onSetSessionType?.(appointmentType);
      setIsOpen(false);
    }
  };

  return (
    <Menu as="div" ref={wrapperRef} className="relative inline-block text-left">
      <Menu.Button
        className={classNames(
          `flex justify-center items-center ${btnWidth} ${btnHeight} !text-${fontSize} font-${fontWeight} rounded-full ${border} border-${borderColor}`,
          btnClasses,
          {
            'bg-blue-600 text-white hover:bg-white hover:text-blue-600':
              (!isOpen && !reverse) || (isOpen && reverse),
            'bg-white text-blue-600':
              (isOpen && !reverse) || (!isOpen && reverse),
          },
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="sr-only">{t('bookNow')}</span>
        {!isMobile ? (
          <>
            <PlusIcon />
            &nbsp;<span>{t('book')}</span>
          </>
        ) : (
          <PlusIcon />
        )}
      </Menu.Button>

      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Menu.Items
          className={classNames(
            'absolute z-999 w-44 p-3 rounded-2xl bg-white shadow-md ring-1 ring-blue-200 focus:outline-none translate-x-0 transform',
            menuPosition,
          )}
        >
          <div className="py-1">
            {bookingOptions.map(({label, appointmentType}, index) => (
              <Menu.Item key={label + index}>
                {({active}) => (
                  <button
                    type="button"
                    className={classNames(
                      'w-full p-2 rounded-lg text-right text-sm font-medium leading-tight hover:bg-blue-600/5',
                      optClasses,
                      {
                        'bg-blue-600/5': active,
                      },
                    )}
                    onClick={() => handleBookingOptionClick(appointmentType)}
                  >
                    {label}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
