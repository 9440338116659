import * as Yup from 'yup';

export const OnboardingDOBSchema = Yup.object()
  .shape({
    dateOfBirth: Yup.object()
      .shape({
        day: Yup.number().required('Select a day'),
        month: Yup.number().required('Select a month'),
        year: Yup.number().required('Select a year'),
      })
      .required(),
  })
  .required();

export const OnboardingGenderSchema = Yup.object()
  .shape({
    gender: Yup.string().required('You must provide a value').nullable(),
  })
  .required();

export const OnboardingCurrentGenderIdentificationSchema = Yup.object()
  .shape({
    currentGenderIdentification: Yup.string()
      .required('You must provide a value')
      .nullable(),
    currentGender: Yup.string().when('currentGenderIdentification', {
      is: value => value === 'Other',
      then: Yup.string().required('You must provide a value').nullable(),
    }),
  })
  .required();

export const OnboardingPregnancyInfoSchema = Yup.object()
  .shape({
    pregnancyInfo: Yup.array()
      .of(
        Yup.string()
          .test(
            'noneExists',
            "You can't choose Non-Applicable with the other options!",
            function validateOption(option) {
              const options = this.parent;
              return !(
                /^nonApplicable$/.test(option || '') &&
                options.includes(option) &&
                options.length > 1
              );
            },
          )
          .required()
          .nullable(),
      )
      .required('You must provide a value')
      .ensure(),
  })
  .required();

export const OnboardingAllergiesSchema = Yup.object()
  .shape({
    drugAllergies: Yup.string().required('You must provide a value'),
    allergies: Yup.string().notRequired(),
  })
  .required();

export const OnboardingShippingSchema = Yup.object()
  .shape({
    shippingAddress: Yup.object()
      .shape({
        streetAddress: Yup.string().required('You must provide a value'),
        city: Yup.string().required('You must provide a value'),
        state: Yup.string().required('You must provide a value'),
        postalCode: Yup.string().required('You must provide a value'),
        country: Yup.string(),
      })
      .required(),
  })
  .required();

export const OnboardingLocalPharmSchema = Yup.object()
  .shape({
    localPharmData: Yup.object().shape({
      pharmName: Yup.string().required('You must provide a value'),
      pharmAddress: Yup.string().required('You must provide a value'),
    }),
  })
  .required();

export const OnboardingDoctorVisitSchema = Yup.object()
  .shape({
    visADocForAnxDep: Yup.string()
      .required('You must provide a value')
      .nullable(),
  })
  .required();

export const OnboardingRegistrationPurposeSchema = Yup.object()
  .shape({
    purposeOfReg: Yup.string().required('You must provide a value').nullable(),
  })
  .required();

export const OnboardingSuicidalThoughtsSchema = Yup.object()
  .shape({
    suicidalThoughts: Yup.string()
      .required('You must provide a value')
      .nullable(),
    suicidalThoughtsInDetails: Yup.string().when('suicidalThoughts', {
      is: 'Yes',
      then: Yup.string().required('You must provide a value'),
    }),
    suicidalThoughtsOthers: Yup.string()
      .required('You must provide a value')
      .nullable(),
    suicidalThoughtsOthersInDetails: Yup.string().when('suicidalThoughts', {
      is: 'Yes',
      then: Yup.string().required('You must provide a value'),
    }),
  })
  .required();

export const OnboardingCurrentlySuicidalSchema = Yup.object()
  .shape({
    currentlySuicidal: Yup.string()
      .required('You must provide a value')
      .nullable(),
    currentlySuicidalInDetails: Yup.string().when('currentlySuicidal', {
      is: 'Yes',
      then: Yup.string().required('You must provide a value'),
    }),
    currentlySuicidalOthers: Yup.string()
      .required('You must provide a value')
      .nullable(),
    currentlySuicidalOthersInDetails: Yup.string().when(
      'currentlySuicidalOthers',
      {
        is: 'Yes',
        then: Yup.string().required('You must provide a value'),
      },
    ),
  })
  .required();

export const OnboardingExistingConditionsSchema = Yup.object()
  .shape({
    existingConditions: Yup.string().notRequired(),
  })
  .required();

export const OnboardingSeizureSchema = Yup.object()
  .shape({
    seizure: Yup.string().required('You must provide a value').nullable(),
  })
  .required();

export const OnboardingMedicationsSchema = Yup.object()
  .shape({
    medicationsForAnxDep: Yup.string()
      .required('You must provide a value')
      .nullable(),
    currentMedications: Yup.string().notRequired(),
  })
  .required();

export const OnboardingDiagnosisSchema = Yup.object()
  .shape({
    diagnosis: Yup.array()
      .of(
        Yup.string()
          .test(
            'noneExists',
            "You can't choose None with the other options!",
            function validateOption(option) {
              const options = this.parent;
              if (typeof option !== 'string') return true;
              return !(
                /^none$/.test(option || '') &&
                options.includes(option) &&
                options.length > 1
              );
            },
          )
          .required(),
      )
      .required('You must provide a value')
      .ensure(),
  })
  .required();

export const OnboardingAnxietySchema = Yup.object()
  .shape({
    anxiety: Yup.object()
      .shape({
        nervous: Yup.string().required('You must provide a value').nullable(),
        controlWorrying: Yup.string()
          .required('You must provide a value')
          .nullable(),
        worryingTooMuch: Yup.string()
          .required('You must provide a value')
          .nullable(),
        troubleRelaxing: Yup.string()
          .required('You must provide a value')
          .nullable(),
        restlessness: Yup.string()
          .required('You must provide a value')
          .nullable(),
        easilyAnnoyed: Yup.string()
          .required('You must provide a value')
          .nullable(),
        fear: Yup.string().required('You must provide a value').nullable(),
      })
      .required(),
  })
  .required();

export const OnboardingDepressionSchema = Yup.object()
  .shape({
    depression: Yup.object()
      .shape({
        littleInterest: Yup.string()
          .required('You must provide a value')
          .nullable(),
        feelingDown: Yup.string()
          .required('You must provide a value')
          .nullable(),
        sleepingTroubles: Yup.string()
          .required('You must provide a value')
          .nullable(),
        feelingTired: Yup.string()
          .required('You must provide a value')
          .nullable(),
        underOrOverEating: Yup.string()
          .required('You must provide a value')
          .nullable(),
        feelBadAboutSelf: Yup.string()
          .required('You must provide a value')
          .nullable(),
        troubleConcentrating: Yup.string()
          .required('You must provide a value')
          .nullable(),
        slowMovtOrFidgety: Yup.string()
          .required('You must provide a value')
          .nullable(),
        thoughtsOfHurtingSelf: Yup.string().required(
          'You must provide a value',
        ),
      })
      .required(),
  })
  .required();

export const OnboardingGovernmentIDSchema = Yup.object()
  .shape({
    governmentID: Yup.array<File>()
      .of<File>(Yup.mixed<File>())
      .required('Government issued ID is required')
      .test('is-correct-file-size', 'image size too large', value => {
        let valid = true;
        if (value) {
          value.forEach(file => {
            const size = file.size / 1024 / 1024;
            if (size > 1) {
              valid = false;
            }
          });
        }
        return valid;
      })
      .nullable(),
  })
  .required();
export const OnboardingSignatureSchema = Yup.object()
  .shape({
    signature: Yup.string().required('You must provide a value'),
  })
  .required();

export const OnboardingWelcomeSchema = Yup.object()
  .shape({
    whyAreYouHere: Yup.array().required('You must provide a value'),
  })
  .required();
